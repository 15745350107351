@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

body {
  background-color: #282c34;
}

h1 {
  font-size: 2.8em;
  margin: 0;
  text-align: center;
}

a {
  text-decoration: none;
  color: lightgray;
}

a:hover {
  color: white;
}

a:visited {
  color: white;
}

button {
  cursor:pointer;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  height: 80px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding-left: 12.5vw;
  padding-right: 12.5vw;
  color: white;
}

.App-link {
  color: #61dafb;
}

.header-logo {
  background: none;
  border: none;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.header-logo-img {
  border-radius: 50%;
  width: 60px;
  height: 60px;
}

.header-logo-txt {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
  font-size: 2em;
  margin-left: 1vw;
  font-family: "Agency FB", serif;
  color: white;
}

.navigation {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.navigation .selected {
  color: white;
}

.nav-button {
  font-family: Im, sans-serif;
  font-size: 1.3em;
  border: 0;
  background: none;
  color: lightgray;
  text-align: right;
  margin-left: 5vw;
  padding: 0;
}

.text-navs {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.nav-button:hover {
  color: white;
}

.nav-icon {
  display: none;
  background: none;
  border: none;
}

.nav-icon:hover {
  color: white;
}

.fa-bars {
  font-size: 2em;
  color: lightgray;
}

.main-content {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.body-text {
  background: rgba(0, 0, 0, 0.3);
  padding-left: 12.5vw;
  padding-right: 12.5vw;
  padding-bottom: 2vh;
  text-align: left;
  color: white;
  min-height: 65vh;
}

/* Body fade transitions */
.body-fade-enter {
  opacity: 0.01;
}

.body-fade-enter.body-fade-enter-active {
  opacity: 1;
  -webkit-transition: opacity 600ms ease-in-out;
  transition: opacity 600ms ease-in-out;
}
/* End of fade transitions */

.portfolio-texts {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.portfolio-entry {
  margin-bottom: 1em;
}

.portfolio-img {
  float: left;
  width: 75vw;
  max-width: 200px;
  border-radius: 5%;
  margin-right: 1em;
}

.contact-text {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  font-size: 1.5em;
}

.app-footer {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: center;
          align-items: center;
  padding-left: 12.5vw;
  padding-right: 12.5vw;
}
.connect {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  text-align: left;
  color: white;
}

.connect-links {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
}

.connect-link {
  text-decoration: none;
  margin-right: 3vw;
}

.connect-icon {
  width: 9vh;
  height: 9vh;
  border-radius: 50%;
}

.resume {
  text-align: left;
  color: white;
}

.resume-img {
  width: 9vh;
}

@media screen and (max-width: 1025px) {
  .App-header {
    padding:  0 1vw 0 1vw;
  }
  .text-navs {
    z-index: 2;
    width: 0;
    visibility: collapse;
    overflow: hidden;
  }
  .navigation .responsive {
    visibility: visible;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    background: #343028;
    padding: 0 2vw 0 2vw;
    position: absolute;
    top: 0;
    right: 0;
    height: 80px;
    width: 100vw;
    -webkit-transition: width 500ms ease-in;
    transition: width 500ms ease-in;
  }
  .nav-icon {
    display: block;
  }
  .main-content {
    padding: 0;
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .contact-text {
    font-size: 1em;
  }
  .app-footer {
    padding: 1vw;
  }
  .connect-icon, .resume-img {
    width: 70px;
    height: 70px;
  }
}

@media screen and (max-width: 400px) {
  .header-logo-txt, .nav-button {
    font-size: 1em;
  }
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

